<template>
   <div :class="['container-privacy', { 'dark-theme': isDarkTheme }]">
      <SWNavButton
          @click="$router.push('/login')"
         class="btn-nav-ragister"
         :size="'small'"
         >Back</SWNavButton
      >
      <div class="text-content-privacy">
         <div class="title-privacy"><p>Privacy Policy</p></div>
         <div class="paragraf-privacy">
            <p>
               This Privacy Policy explains what personal data is collected when you use the our mobile applications,
               websites and the services provided through them (together “App” or “Service”), how such personal data
               will be processed.
            </p>
            <p>
               <strong>
                  By using the service, you promise us that (i) you have read, understand and agree to this privacy
                  policy, and (ii) you are over 16 years of age (or have had your parent or guardian read and agree to
                  this privacy policy for you). </strong
               >If you do not agree, or are unable to make this promise, you must not use the Service. In such case, you
               must (a) delete your account and contact us and request deletion of your data; (b) cancel any
               subscriptions using the functionality provided by Apple (if you are using iOS) or Google (if you are
               using Android), any other app stores that may be available from time to time, or by us if you purchased
               it directly from our websites; and (c) delete the App from your devices.
            </p>
            <p class="privacyParagrafPadding">
               <strong>“GDPR”</strong> means the General Data Protection Regulation (EU) 2016/679 of the European
               Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the
               processing of personal data and on the free movement of such data.
            </p>
            <p>
               <strong>“EEA”</strong> includes all current member states to the European Union and the European Economic
               Area.
            </p>
            <p>
               <strong>“Process”</strong>, in respect of personal data, includes to collect, store, and disclose to
               others.
            </p>
         </div>
         <div class="paragraf-privacy">
            <ul class="contents-list">
               <p class="content-link">Contents:</p>
               <li class="contents-list-item">
                  <a href="#item-content-1" class="contents-list-link">1.1. Categories of personal data we collect</a>
   <!--                   :to="{ hash: '#item-content-1' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >1.1. Categories of personal data we collect</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-2" class="contents-list-link">2.2. For what purposes we process your personal data</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-2' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >2.2. For what purposes we process your personal data</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-3" class="contents-list-link">3.3. Under what legal bases we process your personal data (Applies only to EEA-based users)</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-3' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >3.3. Under what legal bases we process your personal data (Applies only to EEA-based
                     users)</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-4" class="contents-list-link">4.4. With whom we share your personal data</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-4' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >4.4. With whom we share your personal data</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a hrer="#item-content-5" class="contents-list-link">5.5. How you can exercise your privacy rights</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-5' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >5.5. How you can exercise your privacy rights</router-link
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-6" class="contents-list-link">6.6. Age limitation</a>
 <!--                  <router-link
                     :to="{ hash: '#item-content-6' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >6.6. Age limitation</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-7" class="contents-list-link">7.7. International data transfers</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-7' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >7.7. International data transfers</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-8" class="contents-list-link">8.8. Changes to this privacy policy</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-8' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >8.8. Changes to this privacy policy</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-9" class="contents-list-link">9.9. California privacy rights</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-9' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >9.9. California privacy rightsy</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-10" class="contents-list-link">10.10. Data retention</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-10' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >10.10. Data retention</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-11" class="contents-list-link">11.11. How “do not track” requests are handled</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-11' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >11.11. How “do not track” requests are handled</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-12" class="contents-list-link">12.12. Personal data controller</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-12' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >12.12. Personal data controller</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-13" class="contents-list-link">13.13. Contact us</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-13' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >13.13. Contact us</router-link 
                  >-->
               </li>
            </ul>
         </div>
         <div class="paragraf-privacy" id="item-content-1">
            <p>
               1. Categories of personal data we collect We collect data you give us voluntarily (for example, when you
               choose your areas for improvement or send us an email). We also may receive data about you from third
               parties (for example, when you sign in via Apple). Finally, we collect data automatically (for example,
               your IP address).
            </p>
            <p class="title-paragraf">1.1. Data you give us</p>
            <p>You provide us information about yourself when you register for and/or use the Service. For example:</p>
            <ul class="ul-line-height">
               <li>- Basic contact details including your name, address, phone number, email.</li>
               <li>
                  - Order information including your name, billing address, shipping address, payment confirmation,
                  email address, phone number.
               </li>
               <li>- Account information including your username, password, security questions, profile picture.</li>
               <li>
                  - Customer support information including the information you choose to include in communications with
                  us, for example, when sending a message through the Services.
               </li>
            </ul>
            <p class="padding-paragraf">
               <strong>1.2 Data provided by third parties</strong>
            </p>
            <p>
               When you use sign in with Apple or Google or Twitter to register an account in the App, we get personal
               data from your Apple or Google or Twitter account. This data may include, in particular, your name,
               profile picture and verified email address. You may choose to share your data or not.
            </p>
            <p>
               Our Site may provide links to websites or other online platforms operated by third parties. If you follow
               links to sites not affiliated or controlled by us, you should review their privacy and security policies
               and other terms and conditions. We do not guarantee and are not responsible for the privacy or security
               of such sites, including the accuracy, completeness, or reliability of information found on these sites.
               Information you provide on public or semi-public venues, including information you share on third-party
               social networking platforms may also be viewable by other users of the Services and/or users of those
               third-party platforms without limitation as to its use by us or by a third party. Our inclusion of such
               links does not, by itself, imply any endorsement of the content on such platforms or of their owners or
               operators, except as disclosed on the Services.
            </p>
            <p>
               Find more about sign with Apple
               <a href="https://www.apple.com/legal/privacy/data/en/apple-id/">here</a> and Google
               <a href="https://support.google.com/accounts/topic/12843167?hl=en">here.</a>
            </p>
            <p>
               <strong> 1.3.Data we collect automatically:</strong>
            </p>
            <ul class="listPrivacy">
               <li>
                  <strong> Data about how you found us.</strong>
                  <p>
                     We collect data about your referring app or URL (that is, the app or place on the Web where you
                     were when you tapped on our ad).
                  </p>
               </li>
               <li>
                  <strong> Device and Location data</strong>
                  <p>
                     We collect data from your mobile device. Examples of such data include: language settings, IP
                     address, time zone, type and model of a device, device settings, operating system, Internet service
                     provider, mobile carrier, hardware ID, and Facebook ID.
                  </p>
               </li>
               <li>
                  <strong> Usage data</strong>
                  <p>
                     We record how you interact with our Service. For example, we log your taps on certain areas of the
                     interface, the features, and content you interact with, how often you use the App, how long you are
                     in the app, and your subscription orders.
                  </p>
               </li>
               <li class="ul-line-height">
                  <strong> Transaction data</strong>
                  <p>When you make payments through the Service, you need to provide
                  financial account data, such as your credit card number, to our third-party service providers. We do
                  not collect or store full credit card number data, though we may receive credit card-related data,
                  data about the transaction, including: date, time and amount of the transaction, the type of payment
                  method used.
                  </p>
               </li>
               <li>
                  <strong> Cookies</strong>
                  <p>
                     A cookie is a small text file that is stored on a user's computer for record-keeping purposes.
                     Cookies can be either session cookies or persistent cookies. A session cookie expires when you
                     close your browser and is used to make it easier for you to navigate our Service. A persistent
                     cookie remains on your hard drive for an extended period of time. We also use tracking pixels that
                     set cookies to assist with delivering online advertising. Cookies are used, in particular, to
                     automatically recognize you the next time you visit our website. As a result, the information,
                     which you have earlier entered in certain fields on the website may automatically appear the next
                     time when you use our Service. Cookie data will be stored on your device and most of the times only
                     for a limited time period.
                  </p>
               </li>
            </ul>
            <p>
               <strong>Third Party Pixels and Cookies</strong>
            </p>
            <p>
               When you visit our website, log in, register or open an email, cookies, ad beacons, and similar
               technologies may be used by our online data partners or vendors to associate these activities with
               information they or others have about you, including your email address. We (or service providers on our
               behalf) or our affiliates may then send communications and marketing to these email addresses. You may
               opt out of receiving this advertising by clicking <a href="https://app.retention.com/optout">here.</a>
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-2">
            <p class="title-paragraf"><strong>2. For what purposes we process your personal data</strong></p>

            <p>We process your personal data:</p>
            <ul class="listPrivacy2">
               <li><strong>To provide and maintain our Service.</strong></li>
               <li><strong> To notify you about changes to our Service.</strong></li>
               <li><strong>Develop new services.</strong></li>
               <li><strong>To provide customer support.</strong></li>
               <li><strong> To gather analysis or valuable information so that we can improve our Service.</strong></li>
               <li><strong>To monitor the usage of our Service</strong></li>
               <li><strong>To detect, prevent, and address technical issues.</strong></li>
               <li>
                  <strong>
                     To provide you with news, special offers, and general information about other goods, services, and
                     events which we offer that are similar to those that you have already purchased or enquired about,
                     unless you have opted not to receive such information.</strong
                  >
               </li>
               <li><strong>For any other purpose with your consent</strong></li>
               <li>
                  <strong
                     >We may combine voluntarily provided and automatically collected personal information with general
                     information or research data we receive from other trusted sources.
                  </strong>
               </li>
            </ul>
         </div>
         <div class="paragraf-privacy" id="item-content-3">
            <p class="title-paragraf">
               3. Under what legal bases we process your personal data (Applies only to EEA-based users)
            </p>
            <p>
               In this section, we are letting you know what legal basis we use for each particular purpose of
               processing. For more information on a particular purpose, please refer to Section 2. This section applies
               only to EEA-based users.
            </p>
            <p>We process your personal data, in particular, under the following legal bases:</p>
            <p>
               <strong> 3.1 Your consent</strong>
            </p>
            <p>To send you marketing communications</p>
            <p>
               <strong>3.2 To perform our contract with you</strong>
            </p>
            <p>Under this legal basis we:</p>
            <ul class="listPrivacy2">
               <li>
                  Provide our Service (in accordance with our
                  <a href="https://app.sleep-well.coach/terms-of-service">Terms and Conditions of Use)</a>
               </li>
               <li>Customize your experience</li>
               <li>Manage your account and provide you with customer support</li>
               <li>Communicate with you regarding your use of our Service</li>
               <li>Process your payments</li>
            </ul>
            <p>
               <strong>
                  3.3. For our (or others') legitimate interests, unless those interests are overridden by your
                  interests or fundamental rights and freedoms that require protection of personal data</strong
               >
            </p>
            <p>We rely on legitimate interests:</p>
            <ul class="listPrivacy2">
               <li>
                  <strong>To communicate with you regarding your use of our Service</strong>
                  <p>
                     This includes, for example, sending you push notifications reminding you to exercise at appropriate
                     times. The legitimate interest we rely on for this purpose is our interest to encourage you to use
                     our Service more often. We also take into account the potential benefits to you of following your
                     training and nutrition plan, which may lead you to a better and healthier lifestyle.
                  </p>
               </li>
               <li>
                  <strong>To research and analyze your use of the Service</strong>
                  <p>
                     Our legitimate interest for this purpose is our interest in improving our Service so that we
                     understand users’ preferences and are able to provide you with a better experience (for example, to
                     make the use of the App easier and more enjoyable, or to introduce and test new features).
                  </p>
               </li>
               <li>
                  <strong>To send you marketing communications</strong>
                  <p>
                     The legitimate interest we rely on for this processing is our interest to promote our Service in a
                     measured and appropriate way.
                  </p>
               </li>
               <li>
                  <strong>To personalize our ads</strong>
                  <p>
                     The legitimate interest we rely on for this processing is our interest to promote our Service in a
                     reasonably targeted way.
                  </p>
               </li>
               <li>
                  <strong
                     >To enforce our <a href="https://app.sleep-well.coach/terms-of-service">Terms and Conditions of Use</a> and to
                     prevent and combat fraud</strong
                  >
                  <p>
                     Our legitimate interests for this purpose are enforcing our legal rights, preventing and addressing
                     fraud and unauthorised use of the Service, non-compliance with our
                     <a href="https://app.sleep-well.coach/terms-of-service">Terms and Conditions of Use. </a>
                  </p>
               </li>
            </ul>
            <p>
               <strong> 3.4. To comply with legal obligations</strong>
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-4">
            <p class="title-paragraf">4. With whom we share your personal data</p>
            <p>
               We share information with third parties that help us operate, provide, improve, integrate, customize,
               support, and market our Service. We may share some sets of personal data, in particular, for purposes
               indicated in Section 2 of this Privacy Policy. The types of third parties we share information with
               include, in particular:
            </p>
            <p>
               <strong> 4.1 Service providers</strong>
            </p>
            <p>
               We share personal data with third parties that we hire to provide services or perform business functions
               on our behalf, based on our instructions. We share your personal information with the following types of
               service providers:
            </p>
            <ul class="listPrivacy2">
               <li>data analytics providers (Facebook, Google, others)</li>
               <li>measurement partners</li>
               <li>
                  marketing partners (in particular, social media networks, marketing agencies, email delivery services,
                  Facebook, Google, X)
               </li>
               <li>payment processing providers</li>
            </ul>
            <p><strong>4.2 Law enforcement agencies and other public authorities</strong> T</p>
            <p>
               We may use and disclose personal data to enforce our
               <a href="https://app.sleep-well.coach/terms-of-service">Terms and Conditions of Use</a> , to protect our rights,
               privacy, safety, or property, and/or that of our affiliates, you or others, and to respond to requests
               from courts, law enforcement agencies, regulatory agencies, and other public and government authorities,
               or in other cases provided for by law.
            </p>
            <p>
               <strong> 4.3.Third parties as part of a merger or acquisition</strong>
            </p>
            <p>
               As we develop our business, we may buy or sell assets or business offerings. Customers’ information is
               generally one of the transferred business assets in these types of transactions. We may also share such
               information with any affiliated entity (e.g. parent company or subsidiary) and may transfer such
               information in the course of a corporate transaction, such as the sale of our business, a divestiture,
               merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.
            </p>
            <p>
               <strong> 4.4.</strong> You grant the Company the non-exclusive, worldwide, transferable, perpetual,
               irrevocable right to publish, distribute, publicly display and perform the User Content in connection
               with the Service.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-5">
            <p class="title-paragraf">5. How you can exercise your privacy rights</p>
            <p>To be in control of your personal data, you have the following rights:</p>
            <p>
               <strong> / reviewing / updating / correcting your personal data.</strong>You have the right to review,
               edit, or change the personal data that you had previously provided to us in the profile section of the
               App. If you would like to receive a copy of data we process, please send us a data access request.
            </p>
            <p>
               <strong> Deleting your personal data.</strong> You can request erasure of your personal data, as
               permitted by law. When you request deletion of your personal data, we will use reasonable efforts to
               honor your request. In some cases we may be legally required to keep some of the data for a certain time;
               in such event, we will fulfill your request after we have complied with our obligations.
            </p>
            <p>
               <strong> Objecting to or restricting the use of your personal data.</strong> You can ask us to stop using
               all or some of your personal data or limit our use thereof. Additional information for EEA-based users.
               If you are based in the EEA, you have the following rights in addition to the above:
            </p>
            <ul class="listPrivacy2">
               <li class="ul-line-height">
                  <strong> right to lodge a complaint with supervisory authority.</strong> We would love you to contact
                  us directly, so we could address your concerns. Nevertheless, you have the right to lodge a complaint
                  with a competent data protection supervisory authority, in particular in the EU Member State where you
                  reside, work or where the alleged infringement has taken place.
               </li>
               <li class="ul-line-height">
                  <strong>The right to data portability.</strong> If you wish to receive your personal data in a
                  machine-readable format, you can do so by requesting a copy of your personal data as described above.
                  The data will be made available to you in the .json file or other file format.
               </li>
            </ul>
            <p>
               To exercise any of the available to you privacy rights, please send a request to
               <a href="mailto:support@sleep-well.coach">support@sleep-well.coach.</a>
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-6">
            <p class="title-paragraf">6. Age limitations</p>
            <p>
               We do not knowingly process personal data from persons under 16 years of age. If you learn that anyone
               younger than 16 has provided us with personal data, please contact us at
               <a href="mailto:support@sleep-well.coach">support@sleep-well.coach</a> .
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-7">
            <p class="title-paragraf">7. International data transfers</p>

            <p>
               We may transfer personal data to countries other than the country in which the data was originally
               collected in order to provide the Service set forth in the
               <a href="https://app.sleep-well.coach/terms-of-service">Terms and Conditions of Use</a> and for purposes indicated in
               this Privacy Policy. If these countries do not
               have the same data protection laws as the country in which you initially provided the information, we
               deploy special safeguards.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-8">
            <p class="title-paragraf">8. Changes to this privacy policy</p>

            <p>
               We may modify this Privacy Policy from time to time. If we decide to make material changes to this
               Privacy Policy, you will be notified through our Service or by other available means and will have an
               opportunity to review the revised Privacy Policy. By continuing to access or use the Service after those
               changes become effective, you agree to be bound by the revised Privacy Policy.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-9">
            <p class="title-paragraf">9. California privacy rights</p>
            <p>
               This section provides additional details about how we process personal data of California consumers and
               the rights available to them under the California Consumer Privacy Act (“CCPA”) and California’s Shine
               the Light law. Therefore, this section applies only to residents of California, United States.
            </p>
            <p>
               For more details about the personal information we have collected, including the categories of sources,
               please see Section 1 above. We collect this information for purposes described in Section 2 of this
               Privacy Policy. We may also share your information with certain categories of third parties as indicated
               in Section 4.
            </p>

            <p>
               Subject to certain limitations, the CCPA provides California consumers the right to request to know more
               details about the categories or specific pieces of personal information we collect (including how we use
               and disclose this information), to delete their personal information, to opt-out of any “sales” that may
               be occurring, and to not be discriminated against for exercising these rights.
            </p>
            <p>
               California consumers may make a request pursuant to their rights under the CCPA by contacting us at
               <a href="mailto:support@sleep-well.coach">support@sleep-well.coach.</a> We will verify your request and
               inform you accordingly. You may also designate an authorized agent to exercise these rights on your
               behalf.
            </p>
            <p><strong>Access rights under California’s Shine the Light</strong></p>
            <p>
               California also provides its residents with additional access rights. Under Shine the Light law, the
               residents may ask companies once a year what personal information they share with third parties for those
               third parties' direct marketing purposes. Learn more about what is considered to be personal information
               under the statute.
            </p>
            <p>
               To obtain this information from us, please send an email message to
               <a href="mailto:support@sleep-well.coach">support@sleep-well.coach</a> , which includes “Request for
               California Shine the Light Privacy Information” on the subject line and your state of residence and email
               address in the body of your message. Please be aware that not all information sharing is covered by the
               “Shine the Light” requirements and only information on covered sharing will be included in our response.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-10">
            <p class="title-paragraf">10. Data retention</p>

            <p>
               We will store your personal data for as long as it is reasonably necessary for achieving the purposes set
               forth in this Privacy Policy (including providing the Service to you), which includes (but is not limited
               to) the period during which you have an account with the App. We will also retain and use your personal
               data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
            </p>

            <p>
               We have implemented appropriate technical and organizational security measures designed to protect the
               security of any personal information we process. However, please also remember that we cannot guarantee
               that the internet itself is 100% secure. Although we will do our best to protect your personal
               information, transmission of personal information to and from the Service is at your own risk. You should
               only access the Service within a secure environment.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-11">
            <p class="title-paragraf">11. How “Do not track” requests are handled</p>

            <p>
               Except as otherwise stipulated in this Privacy Policy, this App does not support “Do Not Track” requests.
               To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please
               read their privacy policies.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-12">
            <p class="title-paragraf">12. Personal data controller</p>

            <p>
               <span>
                  D8-CREW s.r.o. with its registered office at Oděská 1573, CZ-75661 Rožnov pod Radhoštěm,
                  Czechia, registered in the commercial register under file number C 93267/KSOS in Regional Court in Ostrava, Identification number 19563582,
                  Tax ID (VAT) CZ19563582
               </span>
               will be the controller of your personal data.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-13">
            <p class="title-paragraf">13. Contact us</p>

            <p>
               You may contact us at any time for details regarding this Privacy Policy and its previous versions. For
               any questions concerning your account or your personal data please contact us at
               <a href="support@sleep-well.coach">support@sleep-well.coach.</a>
            </p>
            <p>Effective as of: 16.01.2025</p>
         </div>
      </div>
   </div>
</template>
<script>
import { SWNavButton } from 'sw-ui-kit';

export default {
   components: {
      SWNavButton,
   },
   data() {
      return {
         hasBackPage: false,
      };
   },
   mounted() {
      //this.anchorHashCheck();
   },
   computed: {
      isDarkTheme() {
         return JSON.parse(localStorage.getItem('theme')) === true;
      },
   },
   methods: {
    /*   anchorHashCheck() {
         //console.log(window.location.hash);
         if (window.location.hash) {
            const el = document.getElementById(window.location.hash.slice(1));
            if (el) {
               window.scrollTo(0, el.offsetTop);
            }
         }
      }, */
   },
/*    beforeRouteEnter(to, from, next) {
      next(vm => {
         vm.hasBackPage = !!from.name;
      });
   }, */
};
</script>

<style lang="sass" scoped>
.padding-paragraf
   padding-top: 25px
.ul-line-height
   line-height: 28px
   padding-bottom: 15px
.content-link
   font-size: 22px
   font-weight: 500
.contents-list-item
   font-size: 18px
   margin-bottom: 20px
   font-weight: 400
.content-link
   font-size: 20px
.container-privacy
   width: 100%
   height: 100%
   margin: 0 auto
   max-width: 1246px
.text-content-privacy
   max-width: 870px
   padding-bottom: 50px
   padding-top: 10px
   margin: 0 auto
   font-size: 16px
   font-weight: 400
.btn-nav-ragister
   margin-bottom: 20px
   font-weight: 400
   margin-top: 64px
.title-privacy
    text-align: center
    font-weight: 700
    font-size: 32px
    line-height: 41.6px
    margin-bottom: 40px
.paragraf-privacy
    margin-bottom: 40px
.paragraf-privacy p
    margin-bottom: 20px
.title-paragraf
    font-size: 20px
    font-weight: 500
    line-height: 26px
.lict-one-privacy li
    padding-bottom: 16px

.lict-two-privacy
    line-height: 20.8px
    list-style: none
    margin-left: 0
    padding-left: 0
.lict-two-privacy li
    padding-bottom: 16px
.strong-paragraf-privacy
    font-weight: 500
.lict-theere-privacy
    line-height: 20.8px
    list-style: lower-latin
    margin-left: 17px
    padding-left: 0
.lict-theere-privacy li
    padding-bottom: 16px

.lict-theere-privacy-seven
   line-height: 20.8px
   margin-left: 65px
   padding-left: 0
.lict-theere-privacy-seven li
    padding-bottom: 16px
.corrent
    display: block
.container-privacy p
   line-height: 30px
.linkSplittin
   display: none
.privacyParagrafPadding
   padding-top: 50px
.listPrivacy
   list-style: auto
   margin-left: 40px
.listPrivacy2
   margin-left: 40px
   list-style: initial
.listPrivacy2 li
   padding-bottom: 20px
.smallSizeTextPrivacy
   font-size: 10px

.dark-theme
   color: white

@media screen and (max-width:1024px)
   .text-content-privacy
      margin-top: 40px
   .btn-nav-ragister
      margin-top: 16px
      transform: translateX(-14px)
@media screen and (max-width:420px)
   // .title-privacy
   //    font-size: 24px
   .linkSplittin
      display: block
</style>
